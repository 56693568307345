@import url("https://fonts.googleapis.com/css2?family=Merriweather:wght@700&family=Poppins:wght@500&display=swap");

.activeUni {
  background: #0ea1ff !important;
  color: white !important;
  border: none !important;
}

.coursetype-button {
  background: white;
  color: #0ea1ff;
  border: 1px solid #0ea1ff;
  padding: 10px 10px;
  border-radius: 5px;
}

/* .sem-box{
    box-shadow: 10px 10px 0px 10px black;
    padding:  10px 1rem;
} */
.Subject-Button {
  border: 1px solid rgba(0, 0, 0, 0.6);
  border-radius: 5px;
  cursor: pointer;
}
.activedownload {
  /* color:rgba(0,0,0,0.7) !important; */
  text-decoration: underline;
  transform: scale(1.2);
}
.download-btn {
  transition: all 0.15s ease-in;
  color: blue;
  border: none;
  background-color: white;
}
.accordion-item-study {
  /* border: 1px solid rgba(0, 0, 0, 0.6); */
  border-radius: 5px;
  margin-left: 5px;
}

.study-accordion {
  background: #f3f3f3;
  margin: 2px 0px;
}

.ahsec_banner1 {
  background: linear-gradient(
    -45deg,
    rgb(64, 93, 230),
    rgb(88, 81, 219),
    rgb(131, 58, 180),
    rgb(193, 53, 132),
    rgb(225, 48, 108),
    rgb(253, 29, 29),
    rgb(245, 96, 64),
    rgb(247, 119, 55),
    rgb(252, 175, 69),
    rgb(255, 205, 75),
    rgb(197, 255, 56),
    rgb(81, 255, 0)
  );
  width: 100%;
  background-size: 400% 400% !important;
  -webkit-animation: Gradient 15s ease-in-out infinite;
  animation: Gradient 15s ease-in-out infinite;
  background-size: 400% 400% !important;
  padding-top: 3rem;
  padding-bottom: 14rem;
  position: relative;
  font-family: "Merriweather", serif;
}

.ahsec_banner2 {
  background: linear-gradient(
    -45deg,
    rgb(64, 93, 230),
    rgb(88, 81, 219),
    rgb(131, 58, 180),
    rgb(193, 53, 132),
    rgb(225, 48, 108),
    rgb(253, 29, 29),
    rgb(245, 96, 64),
    rgb(247, 119, 55),
    rgb(252, 175, 69),
    rgb(255, 205, 75),
    rgb(197, 255, 56),
    rgb(81, 255, 0)
  );
  width: 100%;
  background-size: 400% 400% !important;
  -webkit-animation: Gradient 15s ease-in-out infinite;
  animation: Gradient 15s ease-in-out infinite;
}

.sticky-banner {
  background: linear-gradient(
    -45deg,
    rgb(64, 93, 230),
    rgb(88, 81, 219),
    rgb(131, 58, 180),
    rgb(193, 53, 132),
    rgb(225, 48, 108),
    rgb(253, 29, 29),
    rgb(245, 96, 64),
    rgb(247, 119, 55),
    rgb(252, 175, 69),
    rgb(255, 205, 75),
    rgb(197, 255, 56),
    rgb(81, 255, 0)
  );
  width: 100%;
  background-size: 400% 400% !important;
  -webkit-animation: Gradient 15s ease-in-out infinite;
  animation: Gradient 15s ease-in-out infinite;
  background-size: 400% 400% !important;
  position: sticky;
  top: 0px;
  z-index: 10;
  padding: 20px 0px;
  font-family: "Merriweather", serif;
}
.header-banner {
  display: none;
}

.top-heading {
  font-size: 12px;
  margin-bottom: 15px;
}
.pdf-section {
  padding: 20px;
  font-family: "Merriweather", serif;
}
.pdf-box {
  min-height: 100vh;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.09);
  background: white;
  padding: 20px;
  position: relative;
  top: -12rem;
}
.button-div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 4rem;
}
.outer-btn-div {
  background: whitesmoke;
  border-radius: 30px;
  padding: 5px 2px;
}
.outer-btn-div .right-btn {
  border: none;
  padding: 10px 0px;
  width: 150px;
  border-radius: 30px;
  color: blue;
  font-size: 12px;
  font-weight: bold;
}
.outer-btn-div .left-btn {
  border: none;
  background: transparent;
  padding: 10px 0px;
  width: 150px;
  border-radius: 30px;
  font-size: 12px;
  font-weight: bold;
}
.activebtn1 {
  background-color: white !important;
  box-shadow: 0px 0px 10px 0px lightgray;
  transition: all 0.2s ease-in;
}
.activebtn {
  color: white !important;
  transition: all 0.2s ease-in;
  box-shadow: 0px 0px 20px 0px gray;
  background-image: linear-gradient(45deg, #27a2ff 0%, #4062ff 100%);
}
.button-content {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  /* padding: 20px 0px; */
  /* height: 80px; */
  /* background: rgba(0, 0, 0, 0.9); */
}
.button-div2 {
  width: 30%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.Download-text {
  color: #0b62f0;
  border: none;
  background: none;
  font-size: 14px;
}
.circle-download {
  display: none;
}
.Want-More {
  background-color: transparent;
  font-size: 2rem;
  background-image: linear-gradient(180deg, red 0%, #ffac28 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  /* text-fill-color: transparent; */
  background-size: 500% auto;
  animation: textShine 5s ease-in-out infinite alternate;
}
.Want-More2 {
  background-color: transparent;
  font-size: 1rem;
  background-image: linear-gradient(180deg, skyblue 0%, blue 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  /* text-fill-color: transparent; */
  background-size: 500% auto;
  animation: textShine 5s ease-in-out infinite alternate;
}
.Want-More1 {
  background-color: transparent;
  font-size: 1rem;
  background-image: linear-gradient(180deg, yellow 0%, red 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  /* text-fill-color: transparent; */
  background-size: 500% auto;
  animation: textShine 5s ease-in-out infinite alternate;
}

.sticky-text {
  font-size: 12px;
}
.google-button {
  background: none;
  border: none;
}
@keyframes textShine {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

/* .download-btn {
  background: white;
  position: relative;
  color: skyblue;
  box-shadow: 10px 10px 10px 10px lightgray;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  border-radius: 5px;
  transition: 1s all ease;
  animation: animate 0.1s ease;
} */

a {
  text-decoration: none;
}

.eael-creative-button {
  color: #6399ff;
  background-color: #fff;
  box-shadow: 0px 0px 10px 0px rgba(145.03125, 145.03125, 145.03125, 0.5);
  font-weight: 800;
  display: block;
  width: 150px;
  position: relative;
  z-index: 11;
  border-radius: 2px;
  padding: -1px 30px;
  font-size: 16px;
  text-align: center;
  animation: animate 0.2s ease-in-out;
  position: relative;
  top: -8.32rem;
  right: 9rem;
}

.eael-creative-button--wave:before {
  content: "";
  z-index: -1;
  position: absolute;
  top: 50%;
  left: 100%;
  margin: -15px 0 0 1px;
  width: 30px;
  height: 30px;
  border-radius: 50%;

  transform-origin: 100% 50%;
  transform: scale3d(1, 2, 1);

  transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s, -webkit-transform 0.3s;

  transition-timing-function: cubic-bezier(0.7, 0, 0.9, 1);
}

.eael-creative-button--wave:hover:before {
  transform: scale3d(40, 40, 1);
}

.eael-creative-button:hover {
  color: #fff;
}

.eael-creative-button--wave {
  overflow: hidden;
}

.eael-creative-button-icon-left {
  margin-right: 5px;
}

.eael-creative-button--wave:hover:before {
  background-color: #6399ff;
}

.eael-creative-button--wave:hover:before {
  transform: scale3d(40, 40, 1);
}

@keyframes animate {
  0% {
    transform: skew(10deg);
    transform: translateY(-10%);
  }
  50% {
    transform: translateY(10%);
  }

  100% {
    transform: skew(0deg);
    transform: translateY(0%);
  }
}

.ahsec1-heding {
  margin-top: 1rem;
}
.hover-effect {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #07f;
  z-index: 10;
}

@media (max-width: 1024px) {
  .top-heading p {
    font-size: 9px;
  }
  .main-heading h2 {
    font-size: 1.5rem;
  }
  .eael-creative-button {
    top: -9rem;
  }
  .ahsec1-heding h2 {
    font-size: 1.5rem;
  }
  .button-div2 {
    width: 190px;
  }
}

@media (max-width: 991px) {
  .Want-More {
    font-size: 1.5rem;
  }
  .Want-More2 {
    font-size: 0.8rem;
  }
  .Want-More1 {
    font-size: 0.8rem;
  }
  .eael-creative-button {
    right: 147px;
  }
  .ahsec_banner1 {
    padding-bottom: 13rem;
  }
}
@media (max-width: 815px) {
  .eael-creative-button {
    right: 147px;
  }
  .Social-icons span i {
    font-size: 3rem !important;
  }
}

@media (max-width: 768px) {
  .ahsec_banner2-respons {
    /* position: sticky; */
    /* display: block; */
  }
  .ahsec_banner1 {
    padding-bottom: 4rem !important;
  }
  .eael-creative-button {
    position: absolute;
    /* padding: 4px 6px; */
    right: 35.33%;
    top: 0;
    width: 120px;
    animation: taadaa 2s infinite;
    transition: 0.3s ease;
  }
  .eael-creative-button {
    color: white !important;
  }
  .creative-button-inner {
    background-image: linear-gradient(180deg, skyblue 0%, #6399ff 100%);
  }
  .cretive-button-text {
    font-size: 12px;
  }

  @keyframes taadaa {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  #box {
    height: 50px;
    width: 50px;
    margin: 50px auto 0;
    background-color: #e16971;
    animation: taadaa 2s infinite;
  }
  .creative-button-inner,
  .creative-button-inner-1 {
    padding: 0px 0px;
  }
  .pdf-box {
    box-shadow: none;
    padding: 0px;
    margin-top: 256px !important;
  }
  /* .ahsec_banner1 {
    padding: 2rem;
  } */
}

@media (max-width: 767px) {
  .button-div2 {
    width: 50%;
  }
}

.visible {
  display: block;
}
.hidden {
  display: none;
}
@media (max-width: 600px) {
  /* .sticky-banner{
{

} */
  button.learn-more .circle {
    height: 2rem;
    width: 2rem;
  }
  .circle-download {
    width: 40px;
    height: 40px;
  }
  .Download-text {
    width: 200px;
  }
  .ahsec_banner1 {
    padding: 2rem;
  }
  .ahsec1-heding h2 {
    font-size: 1.5rem;
    /* padding: 0px 40px; */
  }
  /* .navabar_menu {
    display: none;
  } */
}

@media (max-width: 575px) {
  .Download-text {
    font-size: 15px;
    width: 140px;
  }
  /* .ahsec1-heding {
    padding: 0px 20px;
  } */
  .main-heading h2 {
    font-size: 1rem;
  }
  .ahsec1-heding h2 {
    font-size: 1rem;
  }
  /* .navabar_menu {
    display: none;
  } */
}
@media (max-width: 500px) {
  .outer-btn-div .right-btn {
    width: 130px;
  }
  .outer-btn-div .left-btn {
    width: 130px;
  }
  .top-heading p {
    font-size: 7px;
  }
}
@media (max-width: 415px) {
  .outer-btn-div .right-btn {
    width: 116px;
  }
  .outer-btn-div .left-btn {
    width: 116px;
  }
}

/* Share With section */

.share-with-heading h1 {
  /* color: lightgray; */
  margin: 0;
  padding: 0;
  /* font-size: 20px; */
  font-weight: 900;
  background-color: transparent;
  background-image: linear-gradient(90deg, #ff40e2 0%, #29c5f2 100%);
  background-size: 300% 300% !important;
  animation: Gradient 10s ease-in-out infinite;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  /* font-family: "Poppins", sans-serif; */
}
@keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* .Social-icons span {
  padding: 40px !important;
} */

.Social-icons {
  /* height: 300px;
  width: 300px; */
  /* padding: 20px; */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s;
}
.Social-icons span i {
  font-size: 4rem;
  /* color: lightgray; */
  background-color: transparent;
  background-image: linear-gradient(90deg, #ff40e2 0%, #29c5f2 100%);
  background-size: 300% 300% !important;
  animation: Gradient 10s ease-in-out infinite;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.5s;
}
.Social-icons:hover .facebook-icon {
  /* background-color: rgba(255, 255, 255, 0.2); */
  -webkit-text-fill-color: #3b5998 !important;
}
.Social-icons:hover .twitter-icon {
  /* background-color: rgba(255, 255, 255, 0.2); */
  -webkit-text-fill-color: #00acee !important;
}
.Social-icons:hover .watsap-icon {
  /* background-color: rgba(255, 255, 255, 0.2); */
  -webkit-text-fill-color: #25d366 !important;
}
.Social-icons:hover span {
  /* transform: translateY(-5%); */
  transition: all 0.2s ease-in-out;
  background: #02010100 !important;

  /* box-shadow: 0px 0px 1px lightgray; */
}

.button-div2 button {
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  background: transparent;
  padding: 0;
  font-size: inherit;
  font-family: "Merriweather", serif;
}

button.learn-more {
  width: 12rem;
  height: auto;
}

button.learn-more .circle {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: relative;
  display: block;
  margin: 0;
  width: 3rem;
  height: 3rem;
  background-image: linear-gradient(180deg, skyblue 0%, #6399ff 100%);
  border-radius: 1.625rem;
}

button.learn-more .circle .icon {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  background: #fff;
}

button.learn-more .circle .icon.arrow {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  left: 0.625rem;
  width: 1.125rem;
  height: 0.125rem;
  background: none;
}

button.learn-more .circle .icon.arrow::before {
  position: absolute;
  content: "";
  top: -0.29rem;
  right: 0.0625rem;
  width: 0.625rem;
  height: 0.625rem;
  border-top: 0.125rem solid #fff;
  border-right: 0.125rem solid #fff;
  transform: rotate(45deg);
}

button.learn-more .button-text {
  transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
  position: absolute;
  top: 5px;
  left: 3px;
  right: 0;
  bottom: 0;
  padding: 0.75rem 0;
  margin: 0 0 0 1.85rem;
  color: rgba(0, 0, 0, 0.5);
  /* font-weight: 600; */
  /* font-weight: ; */
  line-height: 1.6;
  font-size: 11px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 800;
}
.button-div2 button:hover .circle {
  width: 100%;
}

.button-div2 button:hover .circle .icon.arrow {
  background: #fff;
  transform: translate(1rem, 0);
}

.button-div2 button:hover .button-text {
  color: #fff;
}

.head-text {
  color: #898989;
}

.share-box {
  /* background: whitesmoke; */
  /* box-shadow: 0px 0px 5px lightgray; */
  /* border-radius: 10px; */
}

.accordion-position {
  margin-bottom: 5px;
}

.icon-anim svg {
  height: 30px !important;
  width: 30px !important;
}

.view_pdf1 {
  display: none;
}

@media screen and (max-width: 768px) {
  .view_pdf {
    display: none;
  }
  .view_pdf1 {
    display: block;
  }

  .Responsive-design {
    width: 60px;
    height: 60px;
    position: absolute;
    opacity: 0;
    right: 0px;
    top: 12px;
    background: green;
    z-index: 10;
  }
}
