.headings {
  color: rgb(135, 58, 227);
  text-transform: uppercase;
  max-width: 975px;
  margin: 3em auto;
  padding: 0 1.2em;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}
.headings > h4 {
  opacity: 0.6;
  font-size: 15px;
  font-weight: 700;
}
.headings > h5 {
  font-size: 23px;
  font-weight: 700;
}
.quote,
.quote-by {
  color: rgb(122, 122, 122);
  font-weight: 500;
  font-size: 14.7px;
}
.quote {
  margin-top: 0.6rem;
  text-transform: none;
  line-height: 1.5;
}
.quote-by {
  text-align: end;
  text-transform: capitalize;
}
@media only screen and (min-width: 769px) {
  .quote-by {
    padding-right: 1.1rem;
  }
}
.image-container {
  display: flex;
  justify-content: center;
  margin-bottom: 1em;
}
.image-container img {
  margin-top: 2.2rem;
  width: 75%;
  min-width: 285px;
  max-width: 700px;
  aspect-ratio: 3/2;
  border-radius: 1rem;
}

/* calander styling */
.calander-container {
  margin: 2.4rem auto;
  max-width: 420px;
}
@media only screen and (min-width: 724px) {
  .calander-container {
    margin: 0 auto;
    max-width: 480px;
  }
}

.day {
  list-style: none;
  color: black;
  font-weight: 700;
  font-size: 1rem;
  width: 2.3rem;
  height: 2.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #c9eeff;
  transition: all 0.4s;
}
.day:hover {
  background-color: #535bd4;
  color: white;
  cursor: pointer;
}
.days-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-items: center;
  gap: 1.1em;
  /* background-color: beige; */
}
@media only screen and (min-width: 424px) {
  .days-container {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

    /* background-color: beige; */
  }
}

.month {
  text-align: center;
  font-size: 1.4rem;
  font-weight: 600;
  margin: 1.1rem;
}


.calander-container{
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
}