@import url("https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&subset=devanagari,latin-ext");

.contribute_footer .card {
  border-radius: 10px;
  filter: drop-shadow(0 5px 10px 0 #ffffff);
  margin: 1.8rem auto;
  width: 60%;
  /* max-width: 980px; */
  background-color: #ffffff;
  padding: 2em 20px;
  position: relative;
  z-index: 0;
  overflow: hidden;
  transition: 0.6s ease-in;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  text-align: center;
}
.card h1 {
  font-size: 29px;
  font-weight: 400;
}
.card button {
  width: fit-content;
}
.card::before {
  content: "";
  position: absolute;
  z-index: -1;
  top: -15px;
  right: -15px;
  background: #eddbc7;
  height: 100%;
  width: 25px;
  border-radius: 32px;
  transform: scale(1);
  transform-origin: 100% 50%;
  transition: transform 0.25s ease-out;
}

.card:hover::before {
  transition-delay: 0.2s;

  transform: scale(50);
}

.card:hover {
  color: #221c1c;
}

.card p {
  padding: 10px 0;
}
.Payment-content {
  display: flex;
  gap: 1.5rem;
  flex-direction: column;
  max-width: 700px;
}
.Payment-content p {
  color: rgb(1, 0, 0);
  font-size: 15px;
  font-weight: 500;
  letter-spacing: .04ch;
  line-height: 1.5;
  /* text-align: start; */
}
