.footer-bottom-section {
    background-color: #43464c;
    padding: 15px 0;
}

.footer-bottom-section p {
    color: rgba(255,255,255,.32);
    font-size: 16px;
    font-weight: 200;
    margin-bottom: 0;
}
.footer-social-links a {
    color: rgba(255,255,255,.32);
    font-size: 20px;
    transition: .5s all ease-in-out;
}
.footer-social-links a:hover, .footer-social-links a:hover i {
    color: var(--text-white);
    transform: scale(1.1);
    transition: .5s all ease-in-out;
}
.footer-social-links {
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: flex-end;
}

.footer-top-section {
    background-color: #54595f;
    padding: 60px 0;
}
.footer-logo-wrapper {
    /* text-align: center; */
    margin-bottom: 15px;
}
.footer-logo-wrapper img {
    max-width: 60px;
    margin-bottom: 10px;
}
.footer-logo-wrapper p {
    color: var(--text-white);
    font-size: 10px;
    font-family: 'Loved by the King', cursive !important;
}
.footer-wrapper-ul a {
    color: rgba(255,255,255,.32);
    font-size: 17px;
    transition: .5s all ease-in-out;
}
.footer-wrapper-ul a:hover {
    color: var(--text-white);
}
.footer-wrapper-ul li {
    margin-bottom: 5px;
}
.footer-wrapper-ul li:last-child {
    margin-bottom: unset;
}

.footer-wrapper h6 {
    color: var(--text-white);
    font-size: 20px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 15px !important;
}
.footer-logo-wrapper h6 {
    margin-bottom: 5px !important;
}

.footer-logo-wrapper-ul a {
    color: var(--text-white) !important;
    font-size: 14px;
    font-weight: 700;
    display: flex;
    align-items: center;
    gap: 10px;
}

.footer-logo-wrapper-ul {
    display: grid;
    gap: 15px;
}
.footer-logo-wrapper-ul img {
    max-width: 150px;
}