@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Loved+by+the+King&display=swap');


.top_button i{
    border: 1px solid transparent;
    border-radius: 50px;
    padding: 10px;
    background-color:rgb(247, 119, 55) ;
    color: white;
    height: 40px;
    width: 40px;
    position: fixed;
    bottom: 5rem;
    right: 1rem;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    animation: gototop 1.2s linear infinite alternate-reverse;
}


@keyframes gototop {
    0% {
      transform: translateY(-0.5rem);
    }
    100% {
      transform: translateY(1rem);
    }
  
}



* {
  padding: 0;
  margin: 0;
  list-style: none;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

body {
  font-family: 'Poppins', sans-serif !important;
  /* font-family: 'Loved by the King', cursive !important; */
  overflow-x: hidden !important;
}

ul, ol, h1, h2, h3, h4, h5, h6, p {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
}

a {
  text-decoration: none !important;
}

:root {
  --text-white: #fff;
  --text-black: #000;
  --text-gray: #d6dfff;
  --text-gradient: linear-gradient(90deg, #ff40e2 0%, #63dcff 100%);
}







