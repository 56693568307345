.Main-Box {
}
.Main-Box .Image-Box {
  /* border: 2px solid red; */
  height: 100%;
  overflow: hidden;
  z-index: -1;
}

.Earn-Image {
  width: 100%;
  height: 100%;
  /* height: 520px;
  object-fit: cover; */
  /* max-width: 420px; */
}
.Typography-Box {
  margin-top: 10%;
  padding: 0 2.3ch;
  position: relative;
  z-index: 1;
  gap: 1rem;
}
.fixwidetypo {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
  width: 90%;
  text-align: center;
}
.Image-Box {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: -9;
}
.transparent-block {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(240, 255, 255, 0.315);
  z-index: -5;
}
@media screen and (min-width: 992px) {
  .Image-Box {
    z-index: 9;
    position: static;
  }
  .Typography-Box {
    margin-top: -5rem;
  }
}
@media screen and (min-width: 992px) {
  .transparent-block {
    display: none;
  }
}

.fixwidetypo h1 {
  color: #333;
  max-width: 15ch;
}
.fixwidetypo h5 {
  color: #514e4e;
  font-size: 0.92rem;
  line-height: 1.5;
  width: fit-content;
  max-width: 60ch;
}
.button {
  background-color: #02ddf7;
  color: white;
  padding: 8px 19px;
  border-radius: 18px;
  border: none;
  transition: all 0.4s;
}
.button:hover {
  background-color: #ac44f6;
}

/* styling  main typo */
.step-section {
  background-color: #d47528;
  color: white;
  padding-top: 50px;
  padding-bottom: 50px;
  margin-top: 2rem;
}
@media screen and (min-width: 580px) {
  .step-section {
    grid-template-columns: 1fr 1fr;
  }
  .last-element {
    grid-column: span 2;
  }
}
@media screen and (min-width: 992px) {
  .step-section {
    grid-template-columns: 1fr 1fr 1fr;
    margin-top: -2rem;
  }
  .last-element {
    grid-column: span 1;
  }
}

.Content-heading {
  text-align: center;
  font-weight: 500;
  font-size: 40px;
  padding: 20px 0;
}


.Content-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.4rem;
  padding: 1rem 1rem;
  max-width: 500px;
  max-width: 400px;
}


.Content-image {
  aspect-ratio: 1/1;
  width: 40%;
  filter: grayscale(1);
  margin-bottom: 0.8rem;
  transition: transform 0.3s;
}


.Content-image:hover {
  transform: scale(1.08);
}


.Content-box h2 {
  text-align: center;
  letter-spacing: 0.08ch;
  line-height: 1.2;
  font-size: 28px;
}


.Content-box p {
  text-align: center;
  font-size: 14px;
  letter-spacing: 0.08ch;
  line-height: 1.7;
}
