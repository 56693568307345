@media (max-width: 1200px) {
  .navbar-nav {
    gap: 1rem !important;
  }
}
@media (max-width: 1024px) {
  .navbar-nav {
    gap: 0.3rem !important;
  }
}

@media (max-width: 991px) {
  .navbar-nav {
    gap: 1rem !important;
  }
}

@media (min-width: 768px) {
  .navabar_menu .dropdown:hover .dropdown-menu {
    display: block;
    margin-top: 0;
  }

  .navabar_menu_mobile {
    display: none;
  }
  .navabar_menu {
    position: relative !important;
  }
}

@media (max-width: 767px) {
  .navabar_menu .dropdown:hover .dropdown-menu {
    display: unset;
    margin-top: unset;
  }
}

@media (max-width: 600px) {
  .navabar_menu_mobile {
    display: block;
  }

  /* .navabar_menu {
    display: none;
  } */
}

.navbar-nav {
  gap: 1rem;
}

.navabar_menu {
  /* position:sticky; */
  top: 0;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  z-index: 11;
}

.navabar_menu .dropdown-item,
.navabar_menu_mobile .dropdown-item {
  padding: 10px 20px;
  color: #39c7ce !important;
  font-weight: 500 !important;
}

.navabar_menu .dropdown-menu,
.navabar_menu_mobile .dropdown-menu {
  border: none !important;
}

.navabar_menu a {
  font-weight: 700;
  text-transform: uppercase;
  font-size: 13px;
  font-family: "Poppins", sans-serif;
  position: relative;
}

.navabar_menu a:hover {
  color: #39c7ce !important;
  fill: #39c7ce !important;
}

.navabar_menu .dropdown-item:focus,
.navabar_menu .dropdown-item:hover {
  color: #fff !important;
  background-color: #39c7ce;
}
.active-item-header {
  color: #fff !important;
  background-color: #39c7ce;
}

.logo {
  text-decoration: none;
}

.logo h3 span {
  margin: 0;
  padding: 0;
  font-size: 20px;
  font-weight: 900;
  background-color: transparent;
  background-image: linear-gradient(90deg, #ff40e2 0%, #29c5f2 100%);
  -webkit-background-size: 300% 300% !important;
  background-size: 300% 300% !important;
  -webkit-animation: Gradient 10s ease-in-out infinite;
  animation: Gradient 10s ease-in-out infinite;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Poppins", sans-serif;
}

@keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.img_logo img {
  width: 40%;
  height: auto;
}
