.testimonial {
    background: linear-gradient(-45deg, rgb(64, 93, 230), rgb(88, 81, 219), rgb(131, 58, 180), rgb(193, 53, 132), rgb(225, 48, 108), rgb(253, 29, 29), rgb(245, 96, 64), rgb(247, 119, 55), rgb(252, 175, 69), rgb(255, 205, 75), rgb(197, 255, 56), rgb(81, 255, 0));
   width: 100%;
   height:max-content;
    background-size: 400% 400%!important;
    -webkit-animation: Gradient 15s ease-in-out infinite;
    animation: Gradient 15s ease-in-out infinite;
    position: relative;
}


@keyframes Gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.testimonial_title h2,.testimonial_title h5 {
    color: white;
    font-weight: 600;
    padding: 10px 0;
}