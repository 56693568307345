.feedback .heading h1 {
  font-family: "Poppins", sans-serif;
  color: #22252d;
  font-size: 35px;
  font-weight: 500;
  line-height: 1.2em;
  padding: 20px 0;
}

.feedback1 h2,
.feedback_number span a {
  color: #f69679;
  font-size: 30px;
  text-align: center;
  text-decoration: none;
}

.feedback1 a {
  text-decoration: none;
}

.feedback_icons ul {
  list-style: none;
  display: flex;
  gap: 2rem;
  align-items: center;
}

.feedback_icons ul li {
  font-size: 30px;
  background-color: red;
  border-radius: 50%;
  padding: 10px;
}

.feedback_icons li a {
  display: flex;
  width: 35px;
  height: 35px;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 50%;
  font-size: 25px;
  transition-duration: 0.4s;
  text-decoration: none;
}

.feedback_icons ul li.insta {
  background-color: #d6067c;
}

.feedback_icons ul li.fab {
  color: #fff;
  background: #3b5998;
  border: #3b5998;
}

.feedback_icons ul li.ytube {
  background-color: #f93a00;
}

.feedback_icons ul li.twit {
  background-color: #1da1f2;
}

.feedback_icons ul li a:hover {
  transform: rotate(360deg);
  color: white;
}

.form-title p {
  font-size: 20px;
  text-align: center;
  color: #22252d;
  font-family: "Poppins", sans-serif;
  padding: 50px 0;
}

.feedback_form .border {
  width: 100%;
  border: 0.5px solid #000 !important;
  margin: auto;
}


.feedback_form form h2{
    font-family: "Poppins", sans-serif;
    font-size: 35px;
    text-align: center;
    padding: 50px 0;
}

.submit_button .btn{
    background: #0fb8ff;
    padding: 10px;
    border-radius: 50px;
    border: #0fb8ff;
}