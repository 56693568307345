.banner-section {
    position: relative;
}

.banner-content-wrapper {
    text-align: center;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.banner-content-wrapper h1 {
    font-size: 55px;
    font-weight: 900;
    background: var(--text-gradient);
    background-size: 300% 300%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: gradient 10s ease-in-out infinite;
    margin-bottom: 0 !important;
    padding-bottom: 10px !important;
}
.banner-content-wrapper h2 {
    color: var(--text-gray);
    font-family: 'Loved by the King', cursive !important;
    font-size: 25px;
    margin-bottom: 10px !important;
}
.banner-content-wrapper p {
    color: #00000045;
}
.banner-content-wrapper img {
    max-width: 150px;
    margin: 15px 0 20px;
}
.banner-content-wrapper h3 {
    font-size: 20px;
    color: var(--text-white);
    text-shadow: 0px 0px 10px #00d42e;
}

.banner-section video {
    object-fit: cover;
    max-width: 500px;
}

/* .banner-social-links {
    position: absolute;
    left: 70px;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}
.banner-social-links a {
    font-size: 20px;
    color: #818A91;
    transition: .5s all ease-in-out;
}
.banner-social-links a:hover, .banner-social-links a:hover i {
    transform: scale(1.1);
    transition: .5s all ease-in-out;
} */