.form_wrapper {
    padding: 0;
    margin: 50px 0;
    width: 100%;
    height: 100%;
    border-radius:10px 0 0 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    display: flex;
    justify-content: center;
}

.form_wrapper .career_img{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.form_wrapper .career_img img{
    width: 100%;
}

.career_form {
    background: #3b92cd82;
    padding: 20px;
}


.career_form h2{
    color: white;
    font-family: 'Poppins', sans-serif;
    font-size: 40px;
    font-weight: 600;
}


.career .btn {
    background: #3991ce;
    padding: 10px;
    border-radius: 50px;
    border: #3991ce;
}



.career_form input[type="text"] {
    border: 0;
    height: 50px;
    border-radius: 0;
    border-bottom: 1px solid #ebebeb;
  }



  .career_form input:focus {
    border-bottom: 1px solid #007bff;
    box-shadow: none;
    outline: 0;
    background-color: #ebebeb;
  }


  .career_form textarea:focus {
    border-bottom: 1px solid #007bff;
    box-shadow: none;
    outline: 0;
    background-color: #ebebeb;
  }