.hover_img_block:hover {
  transform: scale(1.2);
  transition: all 0.5s;
}
/* form component styling */
.Form-heading {
  text-transform: uppercase;
  font-weight: 500;
  margin: 0em 0 1.5em 0;
  width: 100%;
}
.form-control {
  height: 3em;
  margin: 1em 0 2.2em 0;
}
