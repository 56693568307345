.modalContainer {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  z-index: 9;
}

.modal_show {
  width: 30vw;
  height: auto;
  background-color: white;
  padding: 2rem;
  border-radius: 20px;
  display: block;
}

.modal_header {
  position: relative;
  border-bottom: 1px solid #dddddd;
}

.modal_header-title {
  text-align: center;
}

.close {
  position: absolute;
  top: 0;
  right: 0;
  background: transparent;
  border: none;
}

.modal_content {
  border-bottom: 1px solid #dddddd;
  padding: 2rem 0;
}

.modal_footer {
  padding: 2rem 0;
  padding-bottom: 0;
  display:flex;
  align-items: center;
  justify-content: space-between;
}

.modal-close {
  background-color: transparent;
  border: none;
}

.modal-close:hover {
  color: rgba(54, 67, 72, 0.8);
}

.submit {
  margin-right: 1rem;
  background-color: #364348;
  color: #fff;
  padding: 10px;
}

.submit:hover {
  background-color: rgba(54, 67, 72, 0.8);
}


.modal_overlay {
  position: fixed;
  z-index: 0;
  /* color: white; */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: gray;
  opacity: 0.6; /* Adjust the transparency level as needed */
  /* Ensure the overlay appears on top of other elements */
}