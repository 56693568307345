@import url("https://fonts.googleapis.com/css2?family=Amatic+SC:wght@400;700&family=Poppins:ital,wght@0,100;0,400;1,100;1,200;1,700&display=swap");

/* font-family: 'Amatic SC', cursive;
font-family: 'Poppins', sans-serif; */

/***************** Guahati CSS Starts Here ********************/
.guahati_1 {
  height: 400px !important;
  padding: 30px 0;
}

.guahati_logo img {
  height: 150px;
  width: 150px;
  object-fit: contain;
  padding: 10px;
}

.guahati_banner {
  padding: 40px;
  background-color: rgba(0, 1, 12, 0.83);
}

.gauhati_info a {
  color: white;
  text-decoration: none;
}

.gauhati_info a h3 {
  font-size: 50px;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 9.4px;
  /* font-family: "Amatic SC", cursive; */
  text-align: center;
  padding: 10px 0;
}

.gauhati_info a h5 {
  text-transform: uppercase;
  letter-spacing: 9.4px;
  /* font-family: "Amatic SC", cursive; */
  text-align: center;
  padding: 10px 0;
  font-size: 40px;
  font-weight: 9;
  font-weight: 400;
}

.guahati_content h2 {
  color: #0c0c0c;
  /* font-family: "Amatic SC"; */
  font-size: 50px;
  font-weight: 500;
  text-align: center;
  padding-top: 40px;
}

.guahati_content h3 {
  color: #6ec1e4;
  /* font-family: "Amatic SC"; */
  font-size: 40px;
  font-weight: 500;
  text-align: center;
  padding: 10px 0;
}

.previous_pdf {
  background: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  display: flex;
  flex-direction: column;
}

.previous_pdf .nav-pills .nav-link {
  background-color: #f1f1f1;
  color: #333;
  position: relative;
  padding: 20px;
  font-size: 20px;
}

/* .previous_pdf .nav-pills .nav-link:hover {
  background: #39c7ce;
  color: #fff;
} */

/* .previous_pdf .nav-pills .nav-link.active {
  background-color: #444;
  color: #fff;
} */

/* Add indicator arrow for the active tab */
@media (min-width: 992px) {
  .previous_pdf .nav-pills .nav-link::before {
    content: "";
    display: block;
    border-top: 8px solid transparent;
    border-left: 10px solid #444;
    border-bottom: 8px solid transparent;
    position: absolute;
    top: 50%;
    right: -10px;
    transform: translateY(-50%);
    opacity: 0;
  }
}

.previous_pdf .nav-pills .nav-link.active::before {
  /* opacity: 1; */
}

.previous_pdf .accordion h1 button {
  color: #6ec1e4;
  font-weight: 700;
  font-size: 18px;
}

.previous_pdf .accordion-button:focus {
  z-index: 3;
  border-color: transparent !important;
  outline: 0;
  box-shadow: none !important;
}

.previous_pdf .accordion-button:not(.collapsed) {
  background-color: transparent !important;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
}

.previous_pdf .accordion-body a {
  color: #0170b9;
  text-decoration: none;
  font-size: 18px;
  font-weight: 600;
}

/******************** Guahati CSS Ends Here *****************************/

/************************* NIOS CSS Starts Here **************************/

.nios_1 {
  width: 100%;
  height: 450px !important;
  padding-top: 30px;
}

.nios_banner {
  position: relative;
  padding: 40px;
  background-color: rgba(109, 48, 219, 0.58);
}

.nios_logo {
  text-align: center;
}

.nios_logo img {
  width: 100%;
  height: 200px;
  object-fit: contain;
}

.nios_info a {
  color: white;
  text-decoration: none;
}

.nios_info a h3 {
  font-size: 70px;
  font-weight: 900;
  text-transform: uppercase;
  letter-spacing: 9.4px;
  text-align: center;
  padding: 10px 0;
}

.nios_info a h5 {
  text-transform: uppercase;
  letter-spacing: 9.4px;
  /* font-family: "Amatic SC", cursive; */
  text-align: center;
  padding: 10px 0;
  font-size: 50px;
  font-weight: 9;
}

.choose_title h3 {
  color: #515151;
  font-family: "Poppins", sans-serif;
  font-size: 55px;
  font-weight: 400;
  text-transform: capitalize;
}

.button-select .btn,
.button-select-1 .btn {
  font-size: 20px;
  /* color: #fff;
  background-color: rgba(110,32,249,.77);
  border:rgba(110,32,249,.77) ; */
  color: #0ea1ff;
  background-color: #fff;
  border: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 0px
    rgba(190.65000915527344, 190.65000915527344, 190.65000915527344, 0.5);
  font-weight: 700;
  position: relative;
  backface-visibility: hidden;
  min-width: 200px;
  height: 100%;
  overflow: hidden;
  margin: 10px;
}

.button-select .btn:before,
.button-select-1 .btn:before {
  content: attr(data-text);
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  transform: translate3d(-100%, 0, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
  color: white;
}

.button-select .btn:hover:before,
.button-select-1 .btn:hover:before {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  background-color: #0ea1ff;
}

.creative-button-inner,
.creative-button-inner-1 {
  padding: 15px 20px;
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
  -webkit-transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
}

.button-select .btn:hover > .creative-button-inner {
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
}

.button-select-1 .btn:hover > .creative-button-inner-1 {
  -webkit-transform: translate3d(0, 100%, 0);
  transform: translate3d(0, 100%, 0);
}

.border_line {
  border: 1px solid black;
  width: 100%;
}

.tabs_inner-content h2 {
  color: #0c0c0c;
  /* font-family: "Amatic SC"; */
  font-size: 50px;
  text-align: center;
  padding: 20px 0;
}

.tabs_inner-content h5 {
  color: #6ec1e4;
  /* font-family: "Amatic SC"; */
  font-size: 40px;
  text-align: center;
  padding: 10px 0;
  font-weight: 700;
}

.typewriter_effect {
  color: #7a7a7a;
  font-size: 30px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tabs_button {
  padding: 20px 0;
}

.tabs_button .nav-pills .nav-link {
  background: #f3f3f3;
  border: 0;
  border-radius: 0.25rem;
  margin: 10px;
  color: #999;
  padding: 15px 20px;
  font-size: 20px;
  font-weight: 600;
}

.tabs_button .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #000;
  border-bottom: 5px solid #1e87f0;
}

.tabs_button .tab-content p span {
  display: flex;
  gap: 3rem;
  margin-left: 10px;
}

.tabs_button .tab-content p span a {
  color: #000;
  text-decoration: none;
  font-size: 20px;
}

.btn-check:focus + .btn-primary,
.btn-primary:focus {
  box-shadow: none !important;
}

@media only screen and (max-width: 600px) {
  .tabs_button .tab-content p {
    overflow-x: scroll;
  }
}
/************ NIOS CSS Ends Here ********************/

/***************** CBSE CSS Starts Here ***************/

.cbse_banner_1 {
  min-height: 500px;
  padding-top: 50px;
}

.cbse_banner {
  background-color: rgba(219, 165, 48, 0.77);
  padding: 40px 0;
}

.cbse_img img {
  width: 160px;
  height: 160px;
  object-fit: contain;
}

.cbse_btn .btn,
.cbse_btn .btn {
  font-size: 20px;
  /* color: #fff;
    background-color:#000 !important;
    border: rgba(110,32,249,.77); */
  color: #0ea1ff;
  background-color: #fff;
  border: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 0px
    rgba(190.65000915527344, 190.65000915527344, 190.65000915527344, 0.5);
  font-weight: 700;
  position: relative;
  backface-visibility: hidden;
  min-width: 200px;
  height: 100%;
  overflow: hidden;
  margin: 10px;
}

/************************** CBSE CSS Ends Here ************/

/************************** AHSEC CSS Ends Here ************/

.ahsec_banner {
  background: linear-gradient(
    -45deg,
    rgb(64, 93, 230),
    rgb(88, 81, 219),
    rgb(131, 58, 180),
    rgb(193, 53, 132),
    rgb(225, 48, 108),
    rgb(253, 29, 29),
    rgb(245, 96, 64),
    rgb(247, 119, 55),
    rgb(252, 175, 69),
    rgb(255, 205, 75),
    rgb(197, 255, 56),
    rgb(81, 255, 0)
  );
  width: 100%;
  height: 300px;
  background-size: 400% 400% !important;
  -webkit-animation: Gradient 15s ease-in-out infinite;
  animation: Gradient 15s ease-in-out infinite;
  position: relative;
}

@keyframes Gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.ahsec_title h1 {
  color: #fff;
  font-family: "Poppins";
  font-size: 60px;
  font-weight: 700;
  text-transform: capitalize;
}

.ahsec_title p {
  color: white;
  font-size: 20px;
  font-weight: 300;
}

.ahsec_title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
}

.button_div .btn {
  color: #0ea1ff;
  background-color: #fff;
  border: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 0px
    rgba(190.65000915527344, 190.65000915527344, 190.65000915527344, 0.5);
  padding: 16px;
  font-size: 14px;
  font-weight: 700;
  position: relative;
  overflow: hidden;
  min-width: 150px;
  margin: 5px;
  min-height: 75px;
}

.button_div .btn:before {
  content: "";
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 100%;
  margin: -15px 0 0 1px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  -ms-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
  transform: scale3d(1, 2, 1);
  transition: opacity 0.3s, -webkit-transform 0.3s;
  transition: transform 0.3s, opacity 0.3s;
  transition-timing-function: cubic-bezier(0.7, 0, 0.9, 1);
}

.button_div .btn:hover:before {
  -webkit-transform: scale3d(40, 40, 1);
  transform: scale3d(40, 40, 1);
  color: #fff !important;
  background-color: #0ea1ff;
}

.button_div .btn:hover .button-inner {
  color: #fff;
  position: relative;
  z-index: 2;
}

.first_year_info p {
  color: #898989;
  font-family: "Poppins";
  font-size: 15px;
  font-weight: 400;
  text-decoration: none;
}

.divider {
  border: 1px solid #bebebe;
  width: 10%;
  margin: 40px auto;
}

.first_year_acc .sub-heading {
  color: #c8c8c8;
  font-family: "Poppins";
  font-size: 15px;
  font-weight: 500;
  padding: 15px 0;
}

.first_year_acc {
  position: relative;
}

.first_year_acc .main-heading {
  color: #000;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 30px;
}

.advanced-heading-content {
  color: rgba(156, 156, 156, 0.12);
  z-index: 0;
  font-size: 70px;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 1;
  bottom: 0;
  left: 0;
  width: 100%;
  transition: all 0.3s ease-out;
  position: absolute;
}

.accordion-button::after {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%23333' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' d='M8 0a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2H9v6a1 1 0 1 1-2 0V9H1a1 1 0 0 1 0-2h6V1a1 1 0 0 1 1-1z' clip-rule='evenodd'/%3e%3c/svg%3e");
  transform: scale(0.7) !important;
  display: none;
}
.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%23333' xmlns='http://www.w3.org/2000/svg'%3e%3cpath fill-rule='evenodd' d='M0 8a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H1a1 1 0 0 1-1-1z' clip-rule='evenodd'/%3e%3c/svg%3e");
}

#myAccordion .accordion-button {
  background: #f3f3f3;
  color: black;
  text-align: center;
  font-family: "Poppins";
  font-weight: 600;
}

#myAccordion .accordion-item {
  margin: 5px 0;
}

#myAccordion .accordion-item p:first-child {
  color: #dbdbdb;
  font-size: 20px;
  font-weight: 800;
}

#myAccordion .accordion-item p span a {
  color: black;
  text-decoration: none;
  padding: 0 10px;
}

.Faq h5 {
  color: #c8c8c8;
  font-family: "Poppins";
  font-size: 15px;
  font-weight: 500;
}

.Faq .nav {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  padding: 20px 0;
}

.bottom_div_button .btn {
  color: #000000ba;
  background-color: #fff606;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 0px
    rgba(190.65000915527344, 190.65000915527344, 190.65000915527344, 0.5);
}

.bottom_div_button .btn:hover:before {
  -webkit-transform: scale3d(40, 40, 1);
  transform: scale3d(40, 40, 1);
  color: #fff !important;
  background-color: black;
}

.bottom_div_button .btn:hover .button-inner {
  color: #fff606;
  position: relative;
  z-index: 2;
}

.intro_inner {
  background-color: #f9f9f9;
  width: 100%;
  height: auto;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 25px;
}

.college_intro .intro_inner p,
.college_intro .intro_inner ul {
  color: #a0a0a0;
  font-weight: 400;
  font-size: 18px;
}

.icia_button h2 {
  color: #525252;
  font-size: 25px;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
}

.accordion-item{
  border: none !important;
}

.icia_button .button_div .btn {
  color: #727272;
  background-color: #f3f3f3;
  box-shadow: none;
}

.icia_button h3 {
  color: #52525266;
  font-size: 17px;
  font-weight: 700;
  padding: 10px 0;
}

/************************** AHSEC CSS Ends Here ************/

/*************************** Short Questions CSS Starts Here ****************/

.accordion-item ul li a {
  text-decoration: none;
  color: #000;
  font-weight: 700;
  font-size: 20px;
}

/************************* Short Questions CSS Ends Here *********************/

form {
  margin: 30px 0px;
}

.viewer {
  background-color: #e4e4e4;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 800px;
  overflow-y: auto;
  margin-bottom: 10px;
}

span {
  margin-top: 3px;
}

.view_pdf,
.pdf_1 {
  border: "1px solid rgba(0, 0, 0, 0.3)";
  background: #444746;
  padding: 10px;
  width: 100%;
}


.view_pdf1{
  display: none;
}

@media screen and (max-width: 768px){
  .view_pdf{
    display: none;
  }
  .view_pdf1{
    display: block;
  }
  .Responsive-design {
    width: 60px;
    height: 60px;
    position: absolute;
    opacity: 0;
    right: 0px;
    top: 12px;
    background: green;
    z-index: 10;
  }
}


.view_pdf .rpv-core__viewer.rpv-core__viewer--light {
  height: 100vh !important;
  width: 100%;
  overflow-y: scroll !important;
}

.pdf_content h3 {
  color: red;
  font-family: "Poppins";
  font-size: 20px;
  font-weight: 900;
  text-align: center;
  padding: 10px 0;
}

.previous_one {
  background: transparent;
  box-shadow: none;
}

.previous_one .accordion h1 button {
  color: #333;
  font-weight: 700;
  font-size: 18px;
  background: #f1f1f1;
}

.previous_one .accordion-item {
  /* margin: 10px 0; */
}

.previous_one .accordion-button:not(.collapsed) {
  background: #f1f1f1 !important;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.125);
}

.headingblack h2 {
  color: #6ec1e4;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 40px;
}

.headingblack h1 {
  color: #6ec1e4;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 50px;
  padding: 10px 0;
}

.syllabus_tabs .nav-pills .nav-link.active,
.syllabus_tabs .nav-pills .show > .nav-link {
  /* width: 100%;
  background: #1e87f0; */
  border-bottom: 5px solid #1e87f0;
  transition: all 0.3s ease-out;
}

.syllabus_tabs .nav-pills .nav-link {
  color: #000;
  background: #f3f3f3;
  transition: all 0.3s cubic-bezier(0, 0.49, 0.27, 0.99);
  padding: 10px 20px;
  font-weight: 500;
  margin: 10px;
  font-size: 20px;
}

.syllabus_tabs .tab-content p a {
  color: #333;
  text-decoration: none;
  font-size: 20px;
  font-weight: 600;
}

.home_accordion .accordion-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.home_accordion span.control {
  font-size: 20px;
}

.home_accordion button.accordion-button.collapsed {
  background: linear-gradient(
    -45deg,
    rgb(64, 93, 230),
    rgb(88, 81, 219),
    rgb(131, 58, 180),
    rgb(193, 53, 132),
    rgb(225, 48, 108),
    rgb(253, 29, 29),
    rgb(245, 96, 64),
    rgb(247, 119, 55),
    rgb(252, 175, 69),
    rgb(255, 205, 75),
    rgb(197, 255, 56),
    rgb(81, 255, 0)
  );
  background-size: 400% 400% !important;
  -webkit-animation: Gradient 15s ease-in-out infinite;
  animation: Gradient 15s ease-in-out infinite;
  color: white;
}

/* Activeclass css Institute page*/

.activecourse {
  background: #0ea1ff !important;
  color: white !important;
  /* transform: translateY(-10%) !important; */
}

.activecoursetype {
  background: #0ea1ff !important;
  color: white !important;
  border: none !important;
}

.coursetype-button {
  background: white;
  color: #0ea1ff;
  border: 1px solid #0ea1ff;
  padding: 10px 10px;
  border-radius: 5px;
}

.Module-Text {
  font-size: 14px;
  color: #0ea1ff;
}
.Module-download {
  font-size: 12px;
  color: blue;
  /* margin-left: 10px; */
}

.mobile-pdf {
  display: none;
}
@media (max-width: 991px) {
  .mobile-pdf {
    display: block;
  }
  .desktop-pdf {
    display: none;
  }
}

@media (max-width: 768px) {
}

/* //institutePage */

.study-section .accordion-button {
  font-weight: 600 !important;
  font-size: 14px !important;
  color: #898989;
}

.study-accordion {
  font-weight: 600 !important;
  font-size: 14px;
  color: #898989;
}

.heading-semester {
  color: #898989 !important;
}

/* PreviousQuestionpage.jsx */

.Select-semsester-text {
  color: #898989;
}

@media (max-width: 768px) {
  .Select-semsester-text {
    color: #898989;
    font-size: 14px !important;
  }
  /* .study-accordion{
    font-size: 13px !important;
  } */
  .ahsec_title h1 {
    font-size: 18px;
  }
  .Download-module-text {
    font-size: 15px;
    color: #898989;
  }
}

.accordion-button:not(.collapsed) {
  color: #898989;
  background: #f3f3f3;
}

.accordion-module {
  margin-bottom: 5px !important;
}

.button_div1 .btn {
  color: #0ea1ff;
  background-color: #fff;
  border: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 0px
    rgba(190.65000915527344, 190.65000915527344, 190.65000915527344, 0.5);
  padding: 16px;
  font-size: 14px;
  font-weight: 700;
  position: relative;
  overflow: hidden;
  min-width: 150px;
  margin: 5px;
  min-height: 75px;
}

.module-btn {
  cursor: pointer;
  color: #1e87f0;
  /* background-color: whitesmoke; */
  /* border: 2px solid #1e87f0; */
  border: none;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 700;
  transition: all 0.3s;
  /* box-shadow: 0px 0px 6px 1px lightgray; */
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
}

.module-btn:hover {
  background-color: #1e87f0 !important;
  color: white !important;
  /* border: 2px solid #1e87f0 !important; */
}
