@import url("https://fonts.googleapis.com/css2?family=Amatic+SC:wght@700&display=swap");
/* font-family: 'Amatic SC', cursive; */
.Fixed-container {
  height: 50vh;
  width: 100%;
  background-color: antiquewhite;
  background-image: url(../../assets/images/contributeFixedImage.jpg);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: 89% 31%;
  /* background-position: cover; */
  position: relative;
}
@media only screen and (min-width: 866px) {
  .Fixed-container {
    background-size: cover;

    background-position: 50% 54%;
  }
}
div .mainHeading {
  /* background-color: aquamarine; */
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-family: "Amatic SC", cursive;
  font-weight: 900;
  /* text-shadow: 4px 0px 2px rgba(32, 28, 28, 0.446); */
  text-shadow: 5px 0px 7px rgba(29, 28, 28, 0.89);
  color: rgb(255, 255, 255);
  letter-spacing: 0.6ch;
  font-size: 7rem !important;
}


.main-Box section {
  background-color: black;
  display: flex;
  gap: 2.2em;
  flex-direction: column;
  padding: 2rem 0rem;
  margin-bottom: 0 !important;
}


.contributeImageContainer {
  width: 80%;
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
}

.quote-Typo {
  display: flex;
  justify-content: space-around;
  margin-top: 1rem;
  gap: 26px;
  padding: 1.2rem 2.4rem;
  flex-direction: column;
  color: white;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .quote-Typo {
    text-align: start;
  }
}
.quote-Icon {
  color: #686868;
}
.quote-Typo h2 {
}
.quoteImage {
  max-height: 290px;
}
.quote-Typo p {
  font-size: 18px;
  font-weight: 500;
}
/* logic for order of images */
@media screen and (max-width: 768px) {
  /* container1 */
  /* all container with contributeImageContainer1 class have correct order */
  /* container2 */
  .contributeImageContainer2 .quote-Typo {
    order: 2;
  }
  .contributeImageContainer2 .imgCont {
    order: 1;
  }
}
